/* eslint-disable no-param-reassign */
import { createSlice, Dispatch } from '@reduxjs/toolkit';
import * as activityService from 'activities/services/activities.service';
import { IAuthState, IDefaultReducerState } from 'types/state.interface';
import { finishedLoadingFailure, finishedLoadingSuccess, isLoadingRequest } from 'utils/sliceHelpers';
import { TActivityResultsInResponse, TActivityResultsInResponseGroupByActivitySeries } from '../types/activity.type';
import { ApplicationState } from 'slices';
import { TTemplateReservationField } from '../types/importTemplate.type';
import { TField } from '@timeedit/types/lib/types';
import { EActivityGroupings } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityGroupings.enum';
import dayjs from 'dayjs';
import { EActivityStatus } from '@timeedit/activity-manager-shared-lib/lib/internal/types/Activity/ActivityStatus.enum';
import { omit, pick } from 'lodash';
import TEObjectsService from 'activities/services/TEObjects.service';

type TBasicFilterItem = undefined | string | string[];
type TCategoriedFilterItem = Record<string, string | string[]>;
export interface ActivityState {
  activities: TActivityResultsInResponse[];
  allActivitySeriesIds: string[];
  selectedIds?: string[];
  trigger: number;
  pagination: {
    page: number;
    perPage: number;
    totalActivities: number;
  };
  filter: {
    status?: EActivityStatus[];
    date: TBasicFilterItem;
    [key: string]: TBasicFilterItem | TCategoriedFilterItem;
  };
  loadings: {
    fetch: IDefaultReducerState;
    delete: IDefaultReducerState;
    send: IDefaultReducerState;
  };
  groupBy: TActivityGroupBy;
}

export const initialState: ActivityState = {
  activities: [],
  allActivitySeriesIds: [],
  selectedIds: [],
  pagination: {
    page: 1,
    perPage: 100,
    totalActivities: 1,
  },
  filter: {
    status: undefined,
    date: [dayjs().startOf('year').toJSON(), dayjs().endOf('year').toJSON()],
    objects: {},
  },
  trigger: 0,
  loadings: {
    fetch: {
      loading: false,
      hasErrors: false,
    },
    delete: {
      loading: false,
      hasErrors: false,
    },
    send: {
      loading: false,
      hasErrors: false,
    },
  },
  groupBy: 'ACTIVITY_SERIES',
};

const allocationSlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    fetchActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.fetch);
    },
    fetchActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.fetch);
    },
    fetchActivitiesSuccess: (state: ActivityState, { payload }) => {
      const { results, totalResults, allKeys } = payload;
      finishedLoadingSuccess(state.loadings.fetch);
      state.activities = results;
      state.allActivitySeriesIds = allKeys;
      state.pagination.totalActivities = totalResults;
    },
    fetchActivitiesChildrenSuccess: (state: ActivityState, { payload }) => {
      const { results, activitySeriesId } = payload;

      state.activities = state.activities.map((activity) => {
        if ((activity as TActivityResultsInResponseGroupByActivitySeries).activitySeriesId === activitySeriesId) {
          (activity as TActivityResultsInResponseGroupByActivitySeries).children = results;
        }
        return activity;
      });
    },

    publishActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.send);
    },
    publishActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.send);
    },
    publishActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = undefined;
      finishedLoadingSuccess(state.loadings.send);
    },

    deleteActivitiesRequest: (state: ActivityState) => {
      isLoadingRequest(state.loadings.delete);
    },
    deleteActivitiesFailure: (state: ActivityState) => {
      finishedLoadingFailure(state.loadings.delete);
    },
    deleteActivitiesSuccess: (state: ActivityState, { payload }) => {
      state.trigger += 1;
      state.selectedIds = undefined;
      finishedLoadingSuccess(state.loadings.delete);
    },

    changeTablePagination: (
      state: ActivityState,
      { payload: { page, perPage } }: { payload: { page?: number; perPage?: number } },
    ) => {
      if (page !== undefined) {
        state.pagination.page = page;
      }
      if (perPage !== undefined) {
        state.pagination.perPage = perPage;
      }
    },

    changeFilterValue: (state: ActivityState, { payload }) => {
      const deletedKeys = Object.keys(state.filter)
        .filter((item) => item !== 'date')
        .filter((item) => !payload[item]);
      const updatedFilter = {
        date: state.filter.date, // always keep date
        ...omit(
          {
            ...state.filter,
            ...payload,
          },
          deletedKeys,
        ),
      };
      if (JSON.stringify({ ...state.filter }) !== JSON.stringify(updatedFilter)) {
        state.filter = updatedFilter;
      }
    },

    changeSelectedActivityIds: (state: ActivityState, { payload }: { payload: string[] }) => {
      state.selectedIds = payload;
    },

    changeActivityGroupBy: (state: ActivityState, { payload }: { payload: TActivityGroupBy }) => {
      state.groupBy = payload;
    },

    triggerToFetchActivities: (state: ActivityState) => {
      state.trigger += 1;
    },
  },
});

export const {
  fetchActivitiesRequest,
  fetchActivitiesFailure,
  fetchActivitiesSuccess,

  fetchActivitiesChildrenSuccess,

  publishActivitiesRequest,
  publishActivitiesFailure,
  publishActivitiesSuccess,

  deleteActivitiesRequest,
  deleteActivitiesFailure,
  deleteActivitiesSuccess,

  changeTablePagination,
  changeSelectedActivityIds,
  triggerToFetchActivities,
  changeFilterValue,
  changeActivityGroupBy,
} = allocationSlice.actions;

export type TActivityGroupBy = keyof typeof EActivityGroupings;
type TExportActivityOptions = {
  showDmStatusFilter?: boolean;
  examFlowV3?: boolean;
};

export const fetchActivities =
  (groupBy: TActivityGroupBy, options: TExportActivityOptions) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId) return;
      dispatch(fetchActivitiesRequest());
      const filter = { ...(storeState.activity?.filter ?? {}) };

      if (!options.showDmStatusFilter) {
        delete filter.status;
      }

      const groupedCategories: Record<string, string[]> = {};
      Object.keys(filter).forEach((filterKey) => {
        const { category, field } = activityService.keyToObject(filterKey);
        if (category === 'category') {
          groupedCategories[field] = [...(groupedCategories[field] || []), filterKey];
          // delete filter[filterKey];
        }
      });

      const allCategoryKeys = Object.keys(groupedCategories);
      for (const filterKey of allCategoryKeys) {
        const values = groupedCategories[filterKey].map((fieldKey) => {
          const { key } = activityService.keyToObject(fieldKey);
          return {
            fieldId: TEObjectsService.fields[key]?.id,
            values: filter[fieldKey] as string[],
          };
        });
        // eslint-disable-next-line no-await-in-loop
        let results = await TEObjectsService.searchObjectsByExactFields(filterKey, values);
        // @ts-ignore
        if (!results?.length) results = [{ extId: 'EMPTY_OBJECT ' }]; // Keep this one to make sure it's always being inside api body

        filter[activityService.objectToKey({ category: 'objects', key: filterKey })] = results.map(
          ({ extId }) => extId,
        ) as string[];
      }

      const ssp = {
        groupBy: groupBy || 'ACTIVITY_SERIES',
        matchType: 'ALL',
        page: storeState.activity?.pagination.page,
        limit: storeState.activity?.pagination.perPage,
        filters: activityService.convertToFetchActivitiesFilters(filter),
      };

      if (!options.examFlowV3) {
        ssp.filters = pick(ssp.filters, ['status']);
      }
      if (!options.showDmStatusFilter) {
        ssp.filters = omit(ssp.filters, ['status']);
      }
      const response = await activityService.getActivities(organizationId, ssp);

      console.log('Activity filters:', ssp.filters);
      dispatch(fetchActivitiesSuccess(response));
    } catch (e) {
      dispatch(fetchActivitiesFailure());
      console.error(e);
    }
  };

export const fetchActivitiesChildren =
  (activitySeriesId: string) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesId) return;
      const ssp = {
        groupBy: 'FLAT',
        filters: {
          activitySeriesId: { values: [activitySeriesId] },
        },
      };
      const response = await activityService.getActivities(organizationId, ssp);
      dispatch(fetchActivitiesChildrenSuccess({ results: response.results, activitySeriesId }));
    } catch (e) {
      console.error(e);
    }
  };

export const publishActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(publishActivitiesRequest());
      const response = await activityService.publishActivities(organizationId, activitySeriesIds);
      dispatch(publishActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(publishActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export const deleteActivities =
  (activitySeriesIds?: string[]) =>
  async (dispatch: Dispatch, getState: () => { activity: ActivityState; auth: IAuthState }) => {
    try {
      const storeState = getState();
      const organizationId = storeState.auth?.user?.organizationId;
      if (!organizationId || !activitySeriesIds) return null;
      dispatch(deleteActivitiesRequest());
      const response = await activityService.deleteActivities(organizationId, activitySeriesIds);
      dispatch(deleteActivitiesSuccess(response));
      return response;
    } catch (e) {
      dispatch(deleteActivitiesFailure());
      console.error(e);
      return e;
    }
  };

export default allocationSlice.reducer;

// SELECTORS
export const activitiesSelector = (state: { activity: ActivityState }): TActivityResultsInResponse[] =>
  state.activity.activities;
export const allActivitySeriesIdsSelector = (state: { activity: ActivityState }): string[] =>
  state.activity.allActivitySeriesIds;
export const selectedActivitiesSelector = (state: { activity: ActivityState }) => state.activity.selectedIds;
export const activitiesLoadingSelector = (state: { activity: ActivityState }) => state.activity.loadings.fetch.loading;
export const activitiesSendingSelector = (state: { activity: ActivityState }) => state.activity.loadings.send.loading;
export const activitiesDeletingSelector = (state: { activity: ActivityState }) =>
  state.activity.loadings.delete.loading;
export const activitiesPaginationSelector = (state: { activity: ActivityState }) => state.activity.pagination;
export const activitiesTriggerSelector = (state: { activity: ActivityState }) => state.activity.trigger;

export const activityGroupBySelector = (state: { activity: ActivityState }) => state.activity.groupBy;

export const activityFiltersSelector = (state: { activity: ActivityState }) => state.activity.filter;

export const reservationFieldsForTemplateSelector =
  (mode: string) =>
  (state: ApplicationState): TTemplateReservationField[] => {
    const reservationFields = state.integration.reservationFields[mode];
    if (!reservationFields) return [];
    return reservationFields?.map((field: TField) => {
      return {
        ...field,
        label: field.name,
        excludeInScheduling: false,
        valueType: 'STRING',
      };
    });
  };
