import { useDispatch, useSelector } from 'react-redux';
import {
  activitiesPaginationSelector,
  activitiesTriggerSelector,
  activityFiltersSelector,
  fetchActivities,
  activityGroupBySelector,
  triggerToFetchActivities,
} from '../slices/activity.slice';
import { useCallback, useEffect } from 'react';

import wsClient from 'services/socket.service';
import { useAppFeatureFlags } from '../../hooks/useAppFeatureFlags';
import { ActivityEvents } from '../types/activity.type';

export const useActivitiesWatcher = () => {
  const dispatch = useDispatch();
  const pagination = useSelector(activitiesPaginationSelector);
  const trigger = useSelector(activitiesTriggerSelector);
  const filtersValue = useSelector(activityFiltersSelector);
  const activityGroupBy = useSelector(activityGroupBySelector);
  const { showDmStatusFilter, examFlowV3 } = useAppFeatureFlags();

  const onFetchActivities = useCallback(() => {
    dispatch(fetchActivities(activityGroupBy, { showDmStatusFilter, examFlowV3 }));
  }, [pagination.page, pagination.perPage, filtersValue, dispatch, showDmStatusFilter, activityGroupBy, examFlowV3]);

  useEffect(() => {
    onFetchActivities();
  }, [
    pagination.page,
    pagination.perPage,
    filtersValue,
    dispatch,
    showDmStatusFilter,
    activityGroupBy,
    examFlowV3,
    onFetchActivities,
  ]);

  useEffect(() => {
    if (trigger) {
      onFetchActivities();
    }
  }, [trigger, dispatch, showDmStatusFilter, activityGroupBy, examFlowV3]);

  const onRefetch = () => {
    dispatch(triggerToFetchActivities());
  };

  useEffect(() => {
    document.addEventListener(ActivityEvents.REFETCH_ACTIVITIES_TABLE, onRefetch);

    return () => {
      document.removeEventListener(ActivityEvents.REFETCH_ACTIVITIES_TABLE, onRefetch);
    };
  }, []);

  const onMessageReceived = (event: MessageEvent<any>) => {
    if (!event?.data.startsWith('{')) {
      return;
    }

    try {
      const { eventName } = JSON.parse(event.data) as any; // This assertion is save till we introduce more types of socket messages. Then, we need to adapt!
      if (eventName === 'DRAFT_ACTIVITIES_CHANGED') {
        onFetchActivities();
      }
    } catch (err) {
      console.log('Error when listenning socket io event: ', err);
    }
  };

  useEffect(() => {
    wsClient.instance?.addEventListener('message', onMessageReceived);

    return () => {
      wsClient.instance?.removeEventListener('message', onMessageReceived);
    };
  }, []);
};
