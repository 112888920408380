import React, { useMemo } from 'react';
import ActivityDrawerWrapper from './ActivityDrawerWrapper';
import { DrawerProps } from 'antd';
import { useActivitySeriesGetter } from './ActivityDrawer.hook';
import { ActivityInfo } from './ActivityDrawerComponents';
import ActivityValues, { ActivityTracks, ActivityWeeks } from './ActivityValues';
import intl from 'i18n/intl';
import { compact } from 'lodash';
import { ActivityEvents, TActivityRawValue } from 'activities/pages/types/activity.type';
import { updateActivitiesValues } from 'activities/services/activities.service';

const language = intl.messages as Record<string, string>;

type TActivitySeriesDrawer = DrawerProps & {
  activitySeriesId?: string;
  editable?: boolean;
  onClose?: () => void;
};
function ActivitySeriesDrawer(props: TActivitySeriesDrawer) {
  const { editable, onClose } = props;
  const { activitySeries, loading, activityValues, multipleValuesExtIds } = useActivitySeriesGetter({
    activitySeriesId: props.activitySeriesId,
  });

  const initialValues = useMemo(() => {
    const tmpValues: Record<string, TActivityRawValue> = {};
    [tmpValues.duration] = compact(activitySeries?.durations);
    activityValues?.forEach((item) => {
      tmpValues[item.extId] = item.value;
    });
    return tmpValues;
  }, [activityValues]);

  const onSave = async (updatedValues: Record<string, string | string[]>) => {
    if (!activitySeries || !activitySeries.formId) return;
    await updateActivitiesValues(
      activitySeries.formId.toString(),
      activitySeries.activitySeriesId,
      'ACTIVITY_SERIES',
      updatedValues,
    );
    if (typeof onClose === 'function') {
      document.dispatchEvent(new CustomEvent(ActivityEvents.REFETCH_ACTIVITIES_TABLE));
      onClose();
    }
  };
  return (
    <ActivityDrawerWrapper
      open={props.open}
      onClose={props.onClose}
      title={language['activites.activity_series']}
      initialValues={initialValues}
      primaryObject={activitySeries?.primaryObject}
      editable={editable}
      onSave={onSave}
    >
      <>
        <ActivityInfo
          editable={editable}
          loading={loading}
          activityType={activitySeries?.activityType}
          totalTracks={activitySeries?.numberOfTracks}
        />
        <ActivityValues
          editable={editable}
          loading={loading}
          values={activityValues}
          title={language.track_info}
          multipleValuesExtIds={multipleValuesExtIds}
          tags={activitySeries?.tags}
        >
          <ActivityTracks track={activitySeries?.numberOfTracks} />
          <ActivityWeeks weeks={activitySeries?.startEndDates} editable />
        </ActivityValues>
      </>
    </ActivityDrawerWrapper>
  );
}

export default ActivitySeriesDrawer;
